// Los estilos utilizados por todos los componentes
import "@fontsource/ibm-plex-mono/400.css";
import theme from "./theme";


//------- Utilities -------//
//------------------------//

const displayFlex = {
  display: 'flex'
}

const alignItemsCenter = {
  ...displayFlex,
  alignItems: 'center'
}

const alignItemsEnd = {
  ...displayFlex,
  alignItems: 'flex-end'
}

const flexColumn = {
  ...displayFlex,
  flexDirection: 'column'
}

const flexRow = {
  ...displayFlex,
  flexDirection: 'row'
}

const flexWrap = {
  ...displayFlex,
  flexWrap: 'wrap'
}

const alignSelfFlexStart = {
  alignSelf: 'flex-start'
}

const flexSpaceBetween = {
  justifyContent: 'space-between'
}

const flexCenter = {
  justifyContent: 'center'
}

const floatRight = {
  float: 'right'
}

const mt0 = {
  marginTop: 0
}

const mt40 = {
  marginTop: '40px'
}

const mtAuto = {
  marginTop: 'auto'
}

const mb10 ={
  marginBottom: '10px'
}

const mb20 ={
  marginBottom: '20px'
}

const mb40 ={
  marginBottom: '40px'
}

const mb60 ={
  marginBottom: '60px'
}

const ml10 = {
  marginLeft: '10px'
}

const upperCase = {
  textTransform: 'uppercase',
}


//------- Typography -------//
//------------------------//

const headingXXXL = {
  fontFamily: "IBM Plex Mono",
  fontSize: '88px',
  fontWeight: 300,
  lineHeight: '48px',
  [theme.breakpoints.down('s')]: {
    fontSize: '72px',
  },
}

const headingXXL = {
  fontFamily: "Helvetica",
  fontSize: '64px',
  fontWeight: 300,
  lineHeight: '80px',
  [theme.breakpoints.down('l')]: {
    fontSize: '53px',
    lineHeight: '64px',
  },
  [theme.breakpoints.down('xxs')]: {
    fontSize: '42px',
    lineHeight: '53px',
  },
}

const headingL = {
  fontFamily: "Helvetica",
  fontSize: '53px',
  fontWeight: 300,
  lineHeight: '64px',
  [theme.breakpoints.down('xl')]: {
    fontSize: '48px',
    lineHeight: '53px',
  },
  [theme.breakpoints.down('xxs')]: {
    fontSize: '32px',
    lineHeight: '38px',
  },
}

const headingM = {
  fontFamily: "Helvetica",
  fontSize: '32px',
  fontWeight: 300,
  lineHeight: '38px',
  [theme.breakpoints.down('s')]: {
    fontSize: '28px',
  },
  [theme.breakpoints.down('xxs')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.down('xxxs')]: {
    fontSize: '16px',
  },
}

const headingS = {
  fontFamily: "IBM Plex Mono",
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: 'normal',
  [theme.breakpoints.down('xxs')]: {
    fontSize: '16px',
  },
}

const bodyL = {
  fontFamily: "Helvetica",
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '32.2px',
  [theme.breakpoints.down('xxs')]: {
    fontSize: "21px",
    lineHeight: '24px',
  },
}

const bodyLead = {
  fontFamily: "Helvetica",
  fontSize: '21px',
  fontWeight: 400,
  lineHeight: '36px',
  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
    lineHeight: '32px',
  },
  [theme.breakpoints.down('xxs')]: {
    lineHeight: '24px',
  },
}

const bodyText = {
  fontFamily: "Helvetica",
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '26px',
  [theme.breakpoints.down('xs')]: {
    fontSize: "14px",
    lineHeight: '24px',
  },
}

const mainButtonText = {
  fontFamily: "IBM Plex Mono",
  fontSize: '15px',
  fontWeight: 700,
  lineHeight: '19.5px',
  letterSpacing: 5,
  [theme.breakpoints.down('xl')]: {
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: 4
  },
}

const buttonLinkText = {
  fontFamily: "IBM Plex Mono",
  fontSize: '15px',
  fontWeight: 700,
  lineHeight: 'normal',
  letterSpacing: 5
}

const bodyTextS = {
  fontFamily: "Helvetica",
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: 3,
  [theme.breakpoints.down('xxs')]: {
    fontSize: '12px',
    letterSpacing: 2,
  },
}

const bodyTextXS = {
  fontFamily: "Helvetica",
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '13.8px'
}

const headingXXXLWhitec = {
  ...headingXXXL,
  color: theme.palette.whitec
}
const headingXXLWhitec = {
  ...headingXXL,
  color: theme.palette.whitec
}

const headingXXLBluetec = {
  ...headingXXL,
  color: theme.palette.bluetec
}

const headingLWhitec = {
  ...headingL,
  color: theme.palette.whitec
}

const headingLBluetec = {
  ...headingL,
  color: theme.palette.bluetec
}

const headingLBlack = {
  ...headingL,
  color: theme.palette.black
}

const headingMWhitec = {
  ...headingM,
  color: theme.palette.whitec
}

const headingMBluetec = {
  ...headingM,
  color: theme.palette.bluetec
}

const headingMBlack = {
  ...headingM,
  color: theme.palette.blackCode
}

const headingMWhite = {
  ...headingM,
  color: theme.palette.white
}

const headingSWhitec = {
  ...headingS,
  color: theme.palette.whitec
}

const headingSBluetec = {
  ...headingS,
  color: theme.palette.bluetec
}

const bodyLWhitec = {
  ...bodyL,
  color: theme.palette.whitec
}

const bodyLWhitec300 = {
  ...bodyLWhitec,
  fontWeight: 300,
}

const bodyLBluetec = {
  ...bodyL,
  color: theme.palette.bluetec
}

const bodyLBlack = {
  ...bodyL,
  color: theme.palette.blackCode
}

const bodyLeadWhitec = {
  ...bodyLead,
  color: theme.palette.whitec
}

const bodyLeadBluetec = {
  ...bodyLead,
  color: theme.palette.bluetec
}

const bodyLeadBlack = {
  ...bodyLead,
  color: theme.palette.black
}

const bodyTextWhitec = {
  ...bodyText,
  color: theme.palette.whitec
}

const bodyTextBluetec = {
  ...bodyText,
  color: theme.palette.bluetec
}

const bodyTextBlack = {
  ...bodyText,
  color: theme.palette.blackCode
}

const bodyTextServices = {
  ...bodyText,
  color: theme.palette.grey2
}

const bodyTextSWhitec = {
  ...bodyTextS,
  color: theme.palette.whitec
}

const bodyTextSBluetec = {
  ...bodyTextS,
  color: theme.palette.bluetec
}

const bodyTextSGrey = {
  ...bodyTextS,
  color: theme.palette.grey1
}

const bodyTextXSBluetec = {
  ...bodyTextXS,
  color: theme.palette.bluetec
}

const bodyTextXSBluetecBold = {
  ...bodyTextXSBluetec,
  fontWeight: 700,
}

const bodyTextXSBlack = {
  ...bodyTextXS,
  color: theme.palette.blackCode
}

const bodyTextXSBlackBold = {
  ...bodyTextXSBlack,
  fontWeight: 700,
}

const buttonVerticalTextWhite = {
  ...mainButtonText,
  color: theme.palette.white
}

const buttonVerticalTextBlack = {
  ...mainButtonText,
  color: theme.palette.blackCode
}

const buttonLinkTextBluetec = {
  ...buttonLinkText,
  fontWeight: 400,
  color: theme.palette.bluetec
}
const buttonLinkTextBlack = {
  ...buttonLinkText,
  fontWeight: 400,
  color: theme.palette.blackCode
}


//------- Layouts -------//
//------------------------//

const container = {
  ...displayFlex,
  overflow: 'hidden',
  position: 'relative'
}

const containerClickable = {
  width: '100%', 
  height: '100%', 
  cursor: "pointer"
}

const containerFit = {
  ...container,
  height: 'fit-content'
}

const containerFitWhitec = {
  ...containerFit,
  background: theme.palette.whitec
}

const containerFitBlack = {
  ...containerFit,
  background: theme.palette.blackCode
}

const containerFitBluetec = {
  ...containerFit,
  background: theme.palette.bluetec
}

const containerXL = {
  ...container,
  minHeight: '1197px',
  [theme.breakpoints.down('l')]: {
    minHeight: "unset",
  },
}

const containerL = {
  ...container,
  minHeight: '1024px',
  [theme.breakpoints.down('xl')]: {
    minHeight: "unset",
  },
}

const containerXLWhitec = {
  ...containerXL,
  background: theme.palette.whitec
}

const containerLBlacktec = {
  ...containerL,
  background: theme.palette.blacktec
}

const containerLWhitec = {
  ...containerL,
  background: theme.palette.whitec
}

const containerLBluetec = {
  ...containerL,
  background: theme.palette.bluetec
}

const containerM = {
  ...container,
  minHeight: '920px',
  [theme.breakpoints.down('xl')]: {
    minHeight: "768px",
  },
  [theme.breakpoints.down('l')]: {
    minHeight: "unset",
  },
}

const containerMBlacktec = {
  ...containerM,
  background: theme.palette.blacktec
}

const containerMWhitec = {
  ...containerM,
  background: theme.palette.whitec
}

const containerMS = {
  ...container,
  minHeight: '858px',
  [theme.breakpoints.down('l')]: {
    minHeight: "unset",
  },
}

const containerMSBlacktec = {
  ...containerMS,
  background: theme.palette.blacktec
}

const containerMSWhitec = {
  ...containerMS,
  background: theme.palette.whitec
}

const containerS = {
  ...container,
  minHeight: '517px',
  [theme.breakpoints.down('l')]: {
    minHeight: "unset",
  },
}

const containerSBlacktec = {
  ...containerS,
  background: theme.palette.blacktec
}

const containerSWhitec = {
  ...containerS,
  background: theme.palette.whitec
}

const containerXS = {
  ...container,
  minHeight: '463px',
  [theme.breakpoints.down('l')]: {
    height: "fit-content",
  },
}

const containerXSBlacktec = {
  ...containerXS,
  background: theme.palette.blacktec
}

const containerXSWhitec = {
  ...containerXS,
  background: theme.palette.whitec
}

const containerMenu = {
  ...container,
  minHeight: '96px',
  overflow: 'visible',
}

const containerMenuWhitec = {
  ...containerMenu,
  background: theme.palette.whitec,
  [theme.breakpoints.down('s')]: {
    minHeight: '60px',
  },
}

const containerMenuTransparent = {
  ...containerMenu,
  background: theme.palette.transparent,
  position: 'static'
}

const containerMenuBlacktec = {
  ...containerMenu,
  background: theme.palette.blacktec
}

const containerMethodology = {
  ...containerLBlacktec,
  height: '100vh',
  [theme.breakpoints.down('xl')]: {
    minHeight: "600px",
  },
  [theme.breakpoints.down('m')]: {
    minHeight: "fit-content",
  },
}

const containerRelation = {
  ...containerLWhitec,
  height: '100vh',
  [theme.breakpoints.down('xl')]: {
    minHeight: "600px",
  },
  [theme.breakpoints.down('m')]: {
    minHeight: "fit-content",
  },
}

const containerCaseBanner1 = {
  ...containerMSWhitec,
  [theme.breakpoints.down('l')]: {
    height: "600px",
  },
  [theme.breakpoints.down('s')]: {
    height: "400px",
  },
  [theme.breakpoints.down('xxs')]: {
    height: "250px",
  },
}

const containerCaseBanner2 = {
  ...containerXSBlacktec,
  [theme.breakpoints.down('l')]: {
    height: "300px",
  },
  [theme.breakpoints.down('s')]: {
    height: "200px",
  },
  [theme.breakpoints.down('xxs')]: {
    height: "150px",
  },
}

const containerChallengeBanner = {
  ...containerSBlacktec,
  [theme.breakpoints.down('l')]: {
    height: "350px",
  },
  [theme.breakpoints.down('s')]: {
    height: "250px",
  },
  [theme.breakpoints.down('xxs')]: {
    height: "200px",
  },
}

const BGGrid = {
  position: 'absolute',
  zIndex: 0, 
  width: '75%', 
  height: '100%',
  top: 0,
  left: '50%',
  flexWrap: 'nowrap',
  transform: 'translate(-50%, 0)',
  pointerEvents: 'none',
}

const containerGrid = {
  margin: 'auto',
  width: '75%', 
  alignItems: "center",
  [theme.breakpoints.down('xl')]: {
    margin: "60px auto",
  },
  [theme.breakpoints.down(280)]: {
    margin: "60px 10px",
  },
}

const containerGridReversed = {
  ...containerGrid,
  [theme.breakpoints.down('l')]: {
    margin: "60px auto",
    flexDirection: 'column-reverse'
  },
  [theme.breakpoints.down('s')]: {
    margin: "40px auto",
  },
}

const gridItem = {
  [theme.breakpoints.down('xl')]: {
    margin: "100px auto",
  },
}

const caseListContainerGrid = {
  ...containerGrid,
  [theme.breakpoints.down('xl')]: {
    margin: "auto",
  },
  [theme.breakpoints.down('l')]: {
    margin: "auto",
  },
}


//------- Images -------//
const backgroundImage = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}


//------- Buttons -------//
//------------------------//

const button = { 
  borderRadius: 0,
  zIndex: 4,
  cursor: 'pointer',
  overflow: 'hidden'
}
const mainButton = {
  ...button,
  padding: 2,
  textTransform: 'unset',
  background: theme.palette.bluetec,
  '&:hover': {
    background: theme.palette.bluetecWhite
  }
}

const buttonVertical = {
  ...button,
  border: 2,
  width: '100%',
  justifyContent: 'flex-start',
  padding: "8px 16px",
  textTransform: 'uppercase',
  borderColor: theme.palette.bluetec,
  background: theme.palette.bluetec20,
  overflow: 'hidden',
  '&:focus': {
    background: theme.palette.bluetec,
  },
  '&:hover': {
    background: theme.palette.bluetec,
    color: theme.palette.whitec
  },
  [theme.breakpoints.down('xl')]: {
    padding: "8px",
  },
  [theme.breakpoints.down('l')]: {
    display: 'flow',
  }
}

const buttonVerticalActive = {
  background: theme.palette.bluetec,
  color: theme.palette.whitec
}

const buttonLink = {
  ...button,
  padding: "5px 10px 5px 10px",
  background: 'transparent',
  textTransform: 'unset',
  boxShadow: `0px .8px 0px 0px ${theme.palette.whitec}`,
  marginLeft: 'auto',
  color: theme.palette.whitec,
  '&:hover': {
    background: 'transparent',
    boxShadow: `0px .8px 0px 0px ${theme.palette.bluetec}`,
    color: theme.palette.bluetec
  }
}

const svgButton = { 
  ...button,
  width: '30px', 
  padding: '24px', 
  cursor: 'pointer',
  [theme.breakpoints.down('s')]: {
    padding: '0 10px'
  },
}

const filterMenu = {
  zIndex: 2, 
  cursor: 'grab', 
  margin: '0px', 
  overflowY: 'auto', 
  overflowX: 'auto', 
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  scrollBehavior: 'unset',
  '&::-webkit-scrollbar': {
    width: '0px', 
    background: 'transparent'
  }
}

const filterButton = {
  ...button,
  textAlign:'center', 
  background: theme.palette.whitec, 
  padding: '16px 32px', 
  border: `1px solid ${theme.palette.greytec}`, 
  whiteSpace:'nowrap',
  '&:hover': {
    background: theme.palette.whitecHover
  },
  [theme.breakpoints.down('l')]: {
    '& p': {
      letterSpacing: 3
    },
  },
  [theme.breakpoints.down('l')]: {
    '& p': {
      fontSize: '12px',
      letterSpacing: 2
    },
  },
  [theme.breakpoints.down('xs')]: {
    border: 0, 
  },
}

const navigateButton = {
  ...button,
  padding: "5px 16px", 
  justifyContent: 'left', 
  background: theme.palette.white40, 
  border: `2px solid ${theme.palette.greytec}`,
  '&:hover': {
    borderColor: theme.palette.bluetec,
    background: theme.palette.bluetec20
  },
  '&:focus': {
    background: theme.palette.bluetec,
  },
  '&:focus p': {
    color: theme.palette.white
  },
  [theme.breakpoints.down('l')]: {
    padding: "5px 8px", 
    '& p': {
      fontSize: '12px',
      letterSpacing: 2,
    },
  },
  [theme.breakpoints.down('s')]: {
    width: '100%',
    border: `1px solid ${theme.palette.greytec}`,
  },
}


//------- Card -------//
const card = {
  border: 1,
  borderRadius: 0,
  maxWidth: '100%',
  borderColor: theme.palette.bluetec,
  padding: 4,
  overflow: 'hidden',
  [theme.breakpoints.down('xs')]: {
    padding: 3,
  },
}


//------- Specific -------//
//------------------------//
// Menu
const menuContainer = {
  display: "flex", 
  width: '75%', 
  zIndex: 4, 
  justifyContent: 'space-between',
}

const menuContainerMobile = {
  ...flexColumn, 
  zIndex: 10, 
  height: '100vh', 
  width: '100vw', 
  maxWidth: '100vw',
 position: 'absolute', 
}

const menuLinksContainer = {
  ...alignItemsCenter, 
  gap: 8,
  justifyContent: 'center',
  [theme.breakpoints.down('xl')]: {
    gap: 3,
  },
}

const menuLinksContainerMobile = {
  ...flexColumn,
  ...alignItemsCenter,
  gap: 5,
  justifyContent: 'center',
  margin: 'auto'
}

const menuLink = {
  ...flexColumn,
  alignItems: 'center',
  textDecoration: 'none'
}

const menuButtonText = {
  ...buttonLinkText,
  textTransform: 'uppercase', 
  fontWeight: '500',
  transition: 'color .1s',
  '&:hover': {
    fontWeight: '900'
  },
  [theme.breakpoints.down('l')]: { 
    fontSize: '12px',
    letterSpacing: 3
  },
}

const menuLinksRadius = {
  ...backgroundImage,
  width: '10px',
  height: '10px',
  backgroundPosition: 'center',
  backgroundSize: 'contain'
}

const menuLocaleContainer = {
  ...alignItemsCenter,
  padding: '20px 0',
  width: 'fit-content',
  gap: 3,
  [theme.breakpoints.down('l')]: { 
    gap: 2
  },
}

const menuLogoWrapper = {
  ...alignItemsCenter,
  width: 'fit-content',
  [theme.breakpoints.down('s')]: { 
    marginLeft: '0px'
  },
}

const menuLogoContainer = {
  ...alignItemsCenter,
  gap: 10, 
}

const menuLogo1 = {
  width: '30%',
}

const menuLogo2 = {
  width: '70%',
  maxWidth: '150px',
}

const menuCloseButton = {
  ...buttonLink, 
  ...floatRight, 
  boxShadow: 'unset',
  position: 'absolute',
  top: '10px',
  right: 0
}

// Hero
const containerLHero = {
  ...containerL,
  overflow: 'visible',
  background: `conic-gradient(
    from 180deg at 50% 50%, 
    rgba(229, 166, 217, 0.5) 0deg, 
    rgba(177, 187, 231, 0.5) 63.75deg, 
    rgba(168, 191, 234, 0.5) 120deg, 
    rgba(126, 158, 194, 0.5) 180deg, 
    rgba(165, 163, 202, 0.5) 232.5deg, 
    rgba(209, 142, 159, 0.5) 294.38deg, 
    rgba(229, 166, 217, 0.5) 360deg
  )`
}

const containerLHeroSection = {
  ...containerMSWhitec,
  overflow: 'visible',
  height: 'fit-content',
}

const heroArrowLayout = {
  ...alignItemsCenter,
  justifyContent: 'space-between'
}

const heroSectionContent = {
  ...alignItemsCenter,
  lexGrow: 1, 
  margin: '200px auto',
  [theme.breakpoints.down('xl')]: {
    margin: 'auto',
  } 
}


// Clients
const clientsContainer = {
  ...flexRow,
  zIndex: 2, 
  cursor: 'grab', 
  margin: '0px', 
  gap: 8, 
  overflowY: 'auto', 
  overflowX: 'auto', 
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  scrollBehavior: 'unset',
  '&::-webkit-scrollbar': {
    width: '0px', 
    background: 'transparent'
  },
  [theme.breakpoints.down('xs')]: {
    gap: 5, 
  }
}

const clientsItem = {
  ...alignItemsCenter,
  zIndex: 4, 
  filter: 'grayscale(100%)', 
  transition: 'filter 0.5s', 
  '&:hover': {
    filter: 'none', 
    transition: 'filter 0.5s'
  },
}

const clientsImage = {
  maxHeight: '80px',
  width: 'auto',
  objectFit: 'scale-cover',
  [theme.breakpoints.down('xs')]: {
    width: '50px'
  }
}


// Technology
const technologyImagesContainer = {
  ...alignItemsCenter,
  gap: 2, 
  justifyContent: 'space-around',
  [theme.breakpoints.down('xl')]: {
    flexWrap: "wrap",
  },

}

const cardTechnology = {
  ...card,
  background: theme.palette.transparent,
  color: theme.palette.blackCode,
}

const techImage = {
  ...clientsImage,
  maxWidth: '90%'
}

const aboutImages = {
  objectFit: 'cover', 
  objectPosition: 'bottom right', 
  width: '100%',
  height: '100%',
}

//testimonial
const testimonialCarouselContainer = {
  ...flexRow,
  zIndex: 2, 
  cursor: 'grab', 
  margin: '0px',
  padding: '0px 0px 0px 20%', 
  gap: 3, 
  overflowY: 'auto', 
  overflowX: 'auto', 
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  scrollBehavior: 'unset',
  '&::-webkit-scrollbar': {
    width: '0px', 
    background: 'transparent'
  },
  [theme.breakpoints.down('l')]: {
    padding: '0px 0px 0px 10%', 
  },
  [theme.breakpoints.down('xs')]: {
    padding: '0px', 
  },
}

const testimonialCarouselItem = {
  ...flexColumn,
  zIndex: 3, 
  gap: 2, 
  border: 1, 
  borderColor: 'transparent', 
  padding: '16px', 
  transition: 'all .5s',
  [theme.breakpoints.down('l')]: {
    width: '300px', 
  },
  [theme.breakpoints.down('xxs')]: {
    maxWidth: '250px',
    padding: '0px', 
  },
}

const testimonialCarouselAvatar = {
  ...flexColumn, 
  marginLeft: 'unset',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('xxs')]: {
    width: 'unset', 
  },
}

const testimonialCarouselImage = {
  ...backgroundImage,
  margin: 'unset',
  width: '300px', 
  height: '300px',
  border: `1px solid ${theme.palette.bluetec}`,
  [theme.breakpoints.down('xxs')]: {
    width: '250px', 
    height: '250px',
  },
}

const testimonialItem = {
  ...flexRow,
  zIndex: 3, 
  gap: 8, 
  border: 1, 
  borderColor: 'transparent', 
  transition: 'all .5s',
  [theme.breakpoints.down('xs')]: {
    ...flexColumn,
  },
}

const testimonialAvatar = {
  ...flexColumn,
  width: '400px', 
  height: '400px',  
  justifyContent: 'flex-start'
}

const testimonialImage = {
  ...backgroundImage,
  zIndex: 3,
  margin: 'unset',
  width: '400px', 
  height: '400px',
  border: `1px solid ${theme.palette.bluetec}`,
  [theme.breakpoints.down('xl')]: {
    width: '350px', 
    height: '350px',
  },
  [theme.breakpoints.down('m')]: {
    width: '300px', 
    height: '300px',
  },
  [theme.breakpoints.down('xxs')]: {
    width: '200px', 
    height: '200px',
  },
}


// Case Studies
const caseCarousselBox = {
  ...flexRow,
  zIndex: 2, 
  cursor: 'grab', 
  margin: '0px', 
  gap: 3, 
  overflowY: 'auto', 
  overflowX: 'auto', 
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  scrollBehavior: 'unset',
  '&::-webkit-scrollbar': {
    width: '0px', 
    background: 'transparent'
  }
}

const caseItemLink = {
  position: 'relative', 
  textDecoration: 'unset', 
  width: '305px',
  minWidth: '305px',
  zIndex: 4,
  [theme.breakpoints.down('xxxs')]: {
    minWidth: '75%',
  },
}

const caseImage = {
  ...backgroundImage,
  border: `1px solid ${theme.palette.whitec}`, 
  width: '100%', 
  height: '200px', 
}

const caseTechImage = {
  ...clientsImage,
  maxHeight: '100px',
}

const caseItem = { 
  ...flexColumn, 
  gap: 2, 
  border: '1px solid transparent', 
  padding: '16px', 
  transition: 'all .5s', 
  '&:hover': {
    background: theme.palette.whitec20, 
    borderColor: theme.palette.whitec
  }, 
  '&:hover div': {
    backgroundSize: '200%'
  },
  '&:hover button': {
    background: theme.palette.whitec,
    color: theme.palette.bluetec
  },
}

// Services
const serviceDropdown = {
  ...button,
  ...flexColumn, 
  position: 'relative',
  background: theme.palette.cardDropdown, 
  overflow: 'hidden',
  padding: '16px 24px 16px 24px',
  transition: 'all .5s',
  '&:hover': {
    background: theme.palette.cardDropdownHover
  },
  [theme.breakpoints.down('xxs')]: {
    padding: '10px',
  },
}

const serviceDropdownText = {
  ...flexColumn,
  textAlign: 'left',  
}

const bodyTextServicesTitle = {
  letterSpacing: 2.5,
  fontFamily: "IBM Plex Mono",
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: theme.palette.bluetec,
  [theme.breakpoints.down('xxs')]: {
    fontSize: '14px',
  },
}

const servicesAvatarArrow = {
  ...displayFlex,
  ...alignItemsCenter,
  background: theme.palette.greytec, 
  width: '30px', 
  height: '30px', 
  transition: 'all .5s',  
  '&:hover': {
    transform: 'rotate(180deg)'
  }
}

const servicesBadgeContainer = {
  width: '63px', 
  padding: '8px', 
  textAlign: 'center', 
  background: theme.palette.bluetec,
  [theme.breakpoints.down('xxs')]: {
    padding: '4px',
  },
}


// Methodology
const methodologyBulletPoint = {
  ...alignItemsCenter,
  justifyContent: 'center', 
  width: 20, 
  height: 20, 
  margin: 'auto',
  marginTop: 15,
  marginBottom: 15, 
  background: theme.palette.white20
}

const methodologyInnerBulletPoint = {
  width: 4, 
  height: 4, 
  background: theme.palette.whitec
}

const methodologyHeader = {
  position: 'absolute', 
  transition: 'all .8s',
  width: '20%',
  [theme.breakpoints.down('m')]: {
    position: 'relative', 
    margin: 0,
    width: 'fit-content',
    height: '200px'
  },
  [theme.breakpoints.down('xxxs')]: {
    height: '250px'
  },
} 

// Relation
const relationBulletPoint = {
  ...methodologyBulletPoint,
  background: theme.palette.greytec
}

const relationArrowContainer = {
  position:'absolute', 
  bottom: '5%',
  [theme.breakpoints.down('xl')]: {
    bottom: '2px',
  },
  [theme.breakpoints.down('m')]: {
    maxWidth: '120px',
    right: '8%'
  },
}

// Us
const bodyTextWorkersTitle = {
  ...bodyTextServicesTitle,
  letterSpacing: 1.8,
  fontWeight: 500,
}


// Cases
const buttonLinkCases = {
  ...buttonLink,
  border: `1px solid ${theme.palette.white}`,
  boxShadow: `0px 0px 0px 0px ${theme.palette.whitec}`,
  width: '48px',
  height: '48px',
  '&:hover': {
    background: theme.palette.white,
    color: theme.palette.bluetec
  }
}


// Conversation
const conversationOutterContainer = {
  ...containerMSWhitec,
  [theme.breakpoints.down('l')]: {
    height: "858px",
  },
  [theme.breakpoints.down('xs')]: {
    height: "768px",
  },
  [theme.breakpoints.down('xs')]: {
    height: "768px",
  },
  [theme.breakpoints.down('xs')]: {
    height: "unset",
    padding: '30px 0'
  },
}
const conversationContainer = {
  backgroundColor: theme.palette.white, 
  maxWidth: '50%',
  margin: 'auto', 
  padding: '64px', 
  textAlign: 'center',
  [theme.breakpoints.down('l')]: {
    maxWidth: '75%',
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: '90%',
    padding: '32px', 
  },
}


// Verticals
const cardVerticals = {
  ...card,
  background: theme.palette.bluetec20,
  color: theme.palette.whitec,
}

const verticalImage = {
  ...mb20,
  marginRight: '10px',
  maxWidth: '3rem'
}


// Adn
const adnContainer = {
  justifyContent: 'center',
  width: '676px',
  gap: '32px',
  background: theme.palette.white, 
  opacity: '90%', 
  padding: '112px',
  [theme.breakpoints.down('l')]: {
    padding: '80px',
    width: '70%',
  },
  [theme.breakpoints.down('m')]: {
    padding: '60px',
    width: '80%',
  },
  [theme.breakpoints.down('xs')]: {
    padding: '30px',
    width: '90%',
  },
  [theme.breakpoints.down('xxs')]: {
    padding: '18px',
    width: '100%',
  },
}


// Office
const containerOffice = {
  padding: '50px 0px', 
  background: `conic-gradient(from 180deg at 101.04% 0%, 
    rgba(229, 166, 217, 0.50) 1.2191859521948345e-7deg, 
    rgba(177, 187, 231, 0.50) 63.74999821186066deg, 
    rgba(168, 191, 234, 0.50) 120.00000357627869deg, 
    rgba(126, 158, 194, 0.50) 180deg, 
    rgba(165, 163, 202, 0.50) 232.49999284744263deg, 
    rgba(209, 142, 159, 0.50) 294.3749928474426deg, 
    rgba(229, 166, 217, 0.50) 360deg), var(--White, #FFF)`
}

const locationsWrapper = {
  ...flexRow,
  flexWrap: 'wrap',
  [theme.breakpoints.down('m')]: {
    flexDirection: 'column'
  },
}

const locationsContainer = {
  ...flexRow,
  gap: 1,
  padding: '0 10px 0 0',
  [theme.breakpoints.down('m')]: {
    flexDirection: 'column'
  },
}
const addressWrapper = {
  ...flexColumn,
  justifyContent: 'space-between',
  [theme.breakpoints.down('l')]: {
    justifyContent: 'flex-end'
  },
}

const officeImage = {
  ...backgroundImage,
  minWidth: '400px', 
  minHeight:'400px',
  [theme.breakpoints.down('xxl')]: {
    minWidth: '300px', 
    minHeight:'300px',
  },
  [theme.breakpoints.down('l')]: {
    minWidth: '400px', 
    minHeight:'400px',
  },
  [theme.breakpoints.down('m')]: {
    minWidth: '300px', 
    minHeight:'300px',
  },
  [theme.breakpoints.down('xxs')]: {
    minWidth: '200px', 
  },
}

const officeFrame = {
  zIndex: 4, 
  minWidth: '200px', 
  minHeight: '200px',
}


// Team
const teamContainer = {
  ...displayFlex,
  flexWrap: 'wrap', 
  justifyContent: 'flex-start', 
  marginBottom: '300px',
  [theme.breakpoints.down('l')]: {
    marginBottom: 'unset',
  },
}

const teamWorkerCard = {
  ...alignItemsCenter,
  justifyContent: 'flex-start', 
  padding: '16px 24px', 
  margin: '1% 3%',
  background: theme.palette.cardDropdown, 
  boxShadow: 'unset',
  [theme.breakpoints.down('xl')]: {
    padding: '12px 18px', 
  },
}

const teamWorkerAvatar = {
  width: '60px', 
  height: '60px', 
  bgcolor: theme.palette.bluetec
}

// Footer
const footerContainerGrid = {
  ...containerGrid,
  height: '100%',  
  paddingTop: '50px', 
  paddingBottom: '20px',
  [theme.breakpoints.down('xl')]: {
    padding: '20px 0'
  },
  [theme.breakpoints.down('xxs')]: {
    flexDirection: 'column'
  },
}

const footerLogoSocialContainer = {
  ...flexColumn, 
  ...alignItemsCenter,
  gap: 2,
  width: '100%'
}

const footerAuxLinksContainer = {
  ...flexRow, 
  gap: 2,
  [theme.breakpoints.down('l')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('m')]: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
}

const footerSocialsContainer = {
  ...flexRow,
  justifyContent: 'space-evenly'
}

const footerSocialsIcon = {
  zIndex: 2, 
}

const footerRoutesContainer = {
  ...flexColumn, 
  paddingTop: '60px',
  gap: 3,
  [theme.breakpoints.down('l')]: {
    paddingTop: 0,
  },
}

const footerLocationsWrapper = {
  ...flexRow,
  paddingTop: '60px',
  [theme.breakpoints.down('l')]: {
    ...flexColumn,
    paddingTop: 0,
    gap: 3
  },
  [theme.breakpoints.down('xs')]: {
    ...flexRow,
    gap: 3,
    flexWrap: 'wrap'
  },
}

const footerLocaleContainer = {
  ...menuLocaleContainer,
  margin: 'auto',
  width: 'fit-content',
  marginBottom: '20px'
}

const footerLocaleSeparation = {
  width: '4px', 
  height: '20px', 
  background: theme.palette.bluetec, 
  marginRight: '25px',
  [theme.breakpoints.down('l')]: { 
    marginRight: '20px',
  },
  [theme.breakpoints.down('xxs')]: { 
    width: '2px', 
    marginRight: '10px',
  },
}

const footerLocationsContainer = {
  ...flexColumn,
  gap: 1,
}

const footerUEContainer = {
  ...footerLogoSocialContainer,
}

const footerBottomWrapper = {
  ...alignItemsEnd,
  marginTop: 10,
  [theme.breakpoints.down('l')]: {
    flexDirection: "column",
    alignItems: 'flex-start',
    gap: 2,
    marginTop: 0,
    paddingTop: 0,
  },
}

const footerLink = {
  ...alignItemsCenter,
  gap: 8,
  textDecoration: 'none',
  [theme.breakpoints.down('m')]: {
    width: '20px',
    height: '20px',
  },
}

const footerAuxLink = {
  ...alignItemsCenter,
  textDecoration: 'none',
  zIndex: 4
}

const footerImage = {
  ...alignSelfFlexStart,
  width: '80px', 
  height: '80px'
}

// Case List
const caseListItemWrapper = {
  overflow: "hidden", 
}

const caseListItemContainer = {
  ...flexColumn, 
  padding: '20px',
  maxWidth: '400px',
  height: '100%',
  margin: 0, 
  minHeight: '400px', 
  position: 'relative', 
  transition: 'all .5s', 
  '&:hover': {
    background: theme.palette.bluetec20
  },
  '&:hover #pic': {
    backgroundSize: 'auto'
  },
  '&:hover #link-button': {
    background: theme.palette.white
  }
}

const caseListItemLink = {
  ...flexColumn,
  textDecoration: 'unset', 
  height: '100%', 
  
}

const caseListItemImage = {
  ...mb40, 
  ...backgroundImage, 
  width: '100%', 
  height: '250px' 
}

const caseItemButton = {
  ...buttonLinkCases, 
  ...alignItemsCenter,
  border: `1px solid ${theme.palette.bluetec}`, 
  width: 50, 
  margin: 0
}

const caseListView = {
  ...flexColumn,
  padding: '32px 0px'
}


// Case View
const caseButtonBullet = {
  width: '4px', 
  height: '4px', 
  background: theme.palette.greytec, 
  marginRight: 5
}

const caseContainer = {
  ...containerGrid,
  padding: '100px 0',
  [theme.breakpoints.down('xl')]: {
    padding: '50px 0',
  },
  [theme.breakpoints.down('s')]: {
    padding: '20px 0',
  },
}

const caseIntroductionSubmenu = {
  ...flexColumn, 
  zIndex: 4,
  gap: 2,
  [theme.breakpoints.down('s')]: {
    ...flexWrap,
    gap: 0,
  },

}

const caseGoalsContainer = {
  ...flexRow, 
  ...flexSpaceBetween,
  ...mb40, 
  ...flexWrap,
  gap: 5, 
}

const caseGoalContainer = {
  ...flexColumn, 
  padding: '32px 32px 0 32px', 
  border: `1px solid ${theme.palette.bluetec}`, 
  background: theme.palette.bluetec20,
  [theme.breakpoints.down('l')]: {
    padding: '24px 24px 0 24px', 
  },
  [theme.breakpoints.down('s')]: {
    padding: '16px 16px 0 16px', 
  },
}

const caseTestimonialsText = {
  ...headingMWhitec,
  fontStyle: 'italic',
  [theme.breakpoints.down('xxs')]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}

const caseSolutionText = {
  ...headingMWhitec,
  fontSize: 28,
  [theme.breakpoints.down('xl')]: {
    fontSize: 24
  },
  [theme.breakpoints.down('s')]: {
    fontSize: 28
  },
}

const improvementsContainer = {
  ...containerFitBluetec,
  padding: '100px 0',
  [theme.breakpoints.down('xl')]: {
    padding: '50px 0',
  },
  [theme.breakpoints.down('s')]: {
    padding: '20px 0',
  },
}

const improvementsHeadingMWhitec = {
  ...headingMWhitec,
  [theme.breakpoints.down('xl')]: {
    fontSize: '28px',
  },
}

const relatedContainer = {
  ...containerFitWhitec,
  padding: '100px 0',
  [theme.breakpoints.down('xl')]: {
    padding: '50px 0',
  },
  [theme.breakpoints.down('s')]: {
    padding: '20px 0',
  },
}

const improvementType1Container = {
  ...flexColumn, 
  height: 300
}

const improvementType2Container = {
  ...mb20, 
  ...flexColumn, 
  minHeight: 450, 
  padding: '24px', 
  border: `2px solid ${theme.palette.whitec}`, 
  background: theme.palette.white20,
  [theme.breakpoints.down('l')]: {
    minHeight: 'fit-content'
  },
}

const caseResultImage = {
  ...mb40, 
  width: '100%', 
  height: 600, 
  [theme.breakpoints.down('l')]: {
    height: 500, 
  },
  [theme.breakpoints.down('s')]: {
    height: 400, 
  },
  [theme.breakpoints.down('xs')]: {
    height: 300, 
  },
  [theme.breakpoints.down('xxs')]: {
    height: 200, 
  },
}

const solutionContainer = {
  ...improvementType2Container,
  ...flexSpaceBetween,
  minHeight: 300,
  [theme.breakpoints.down('s')]: {
    height: 'auto',
    minHeight: '150px',
    width: '100%'
  },
}

// Form
const formContactContainer = {
  ...flexColumn,
  justifyContent: 'space-between', 
  position: 'fixed', 
  top: '50%', 
  left: '50%', 
  maxHeight: '100vh',
  transform: 'translateX(-50%) translateY(-50%)', 
  padding: '12px 38px 48px 38px', 
  width: '30%',
  maxWidth: 500, 
  minHeight: 650, 
  zIndex: 10, 
  background: theme.palette.whitec,
  [theme.breakpoints.down('xl')]: {
    width: '40%', 
  },
  [theme.breakpoints.down('l')]: {
    width: '50%', 
  },
  [theme.breakpoints.down('m')]: {
    width: '75%', 
  },
  [theme.breakpoints.down('xxs')]: {
    width: '100%', 
  },
}

const formApplicationContainer = {
  ...flexColumn, 
  gap: 2, 
  width: '100%',
  [theme.breakpoints.down('xs')]: {
    gap: 1, 
  },
}

const formFileSelectorContainer = {
  cursor: 'pointer', 
  '&:hover': {
    background: theme.palette.whitec40
  }
}

const formBusinessApplicationContainer = {
  ...formContactContainer,
  background: theme.palette.bluetec
}

const formInput = {
  ...bodyLeadWhitec, 
  width: '100%',
  padding: '8px 12px', 
  background: theme.palette.white60,
  [theme.breakpoints.down('xs')]: {
    padding: '4px 6px', 
  },
}

const formCloseButton = {
  ...buttonLink, 
  ...floatRight, 
  boxShadow: 'unset',
  background: theme.palette.whitec,
  '&:hover': {
    background: theme.palette.white60
  },
  '&:hover p': {
    color: theme.palette.whitec
  }
}

const formCloseButtonText = {
  ...buttonLinkText, 
  color: theme.palette.bluetec,
  letterSpacing: 'unset',
}

const formButtonSubmit = {
  ...formCloseButton,
  background: theme.palette.green
}

export { 
  // Utilities
  displayFlex, flexRow, alignSelfFlexStart, alignItemsCenter, alignItemsEnd, flexSpaceBetween, flexWrap,
  mt0, mt40, mb10, mb20, mb40, mb60, ml10, floatRight, upperCase, mtAuto, flexColumn, flexCenter,
  // Typography
  headingXXXLWhitec,
  headingXXLWhitec, headingXXLBluetec, 
  headingLWhitec, headingLBluetec, headingLBlack,
  headingMWhitec, headingMBluetec, headingMWhite, headingMBlack,
  headingSWhitec, headingSBluetec, 
  bodyLWhitec, bodyLBluetec, bodyLWhitec300, bodyLBlack,
  bodyLeadWhitec, bodyLeadBluetec, bodyLeadBlack,
  bodyTextWhitec, bodyTextBluetec, bodyTextBlack, bodyTextServices, bodyTextServicesTitle, bodyTextWorkersTitle,
  bodyTextS, bodyTextSWhitec, bodyTextSBluetec, bodyTextSGrey,
  bodyTextXS, bodyTextXSBluetec, bodyTextXSBluetecBold, bodyTextXSBlack, bodyTextXSBlackBold,
  // Layout
  containerFitWhitec, containerFitBlack, containerFitBluetec,
  containerXLWhitec,
  containerLBlacktec, containerLWhitec, containerLBluetec, containerLHero, containerLHeroSection,
  containerMBlacktec, containerMWhitec,
  containerMSBlacktec, containerMSWhitec,
  containerSBlacktec, containerSWhitec,
  containerXSBlacktec, containerXSWhitec,
  containerMenuBlacktec, containerMenuWhitec, containerMenuTransparent,
  BGGrid, containerGrid, containerClickable, gridItem, containerGridReversed,
  // Images
  backgroundImage,
  // Specific - Menu
  menuContainer, menuLinksContainer, menuLinksRadius, menuLink, menuButtonText,
  menuLocaleContainer, menuLogoContainer, menuLogoWrapper, menuLogo1, menuLogo2,
  menuLinksContainerMobile, menuCloseButton, menuContainerMobile,
  // Specific - Hero
  heroArrowLayout, heroSectionContent,
  // Specific - Technology
  technologyImagesContainer,
  // Specific - About
  aboutImages,
  // Specific - Testimonial
  testimonialCarouselContainer, testimonialCarouselItem, testimonialCarouselAvatar,
  testimonialCarouselImage, testimonialItem, testimonialImage, testimonialAvatar,
  // Specific - Conversation
  conversationContainer, conversationOutterContainer,
  // Specific - Adn
  adnContainer,
  // Specific - Footer
  footerLogoSocialContainer, footerSocialsContainer, footerRoutesContainer, footerLink,
  footerSocialsIcon, footerContainerGrid, footerLocationsContainer, footerImage, footerAuxLink,
  footerAuxLinksContainer, footerLocationsWrapper, footerUEContainer, footerBottomWrapper,
  footerLocaleContainer, footerLocaleSeparation,
  // Specific - Case Studies
  caseCarousselBox, caseItem, caseItemLink, caseImage, caseTechImage,
  // Specific - Services
  servicesAvatarArrow, servicesBadgeContainer,
  // Specific - Methodology
  methodologyBulletPoint, methodologyInnerBulletPoint, methodologyHeader,
  containerMethodology,
  // Specific - Relation
  relationBulletPoint, relationArrowContainer, containerRelation,
  // Specific - Office
  containerOffice, officeImage, officeFrame, locationsWrapper, addressWrapper,
  locationsContainer,
  // Specific - Team
  teamContainer, teamWorkerCard, teamWorkerAvatar,
  // Specific - Case List 
  caseListView, caseListItemContainer, caseListItemImage, caseItemButton, caseListItemWrapper,
  caseListItemLink, caseListContainerGrid,
  // Specific - Case View
  caseButtonBullet, caseContainer, caseGoalsContainer, caseGoalContainer, improvementsContainer,
  improvementType1Container, improvementType2Container, relatedContainer, caseResultImage,
  solutionContainer, containerCaseBanner1, containerCaseBanner2, containerChallengeBanner,
  caseTestimonialsText, caseSolutionText, caseIntroductionSubmenu, improvementsHeadingMWhitec,
  // Specific - Form
  formContactContainer, formBusinessApplicationContainer, formInput, formApplicationContainer,
  formFileSelectorContainer, formCloseButton, formCloseButtonText, formButtonSubmit,
  
  mainButton, mainButtonText, svgButton, filterButton, navigateButton, filterMenu,
  buttonVertical, buttonVerticalActive, buttonVerticalTextWhite, buttonVerticalTextBlack,
  buttonLink, buttonLinkText, buttonLinkTextBluetec, buttonLinkTextBlack, buttonLinkCases,
  cardVerticals, cardTechnology, verticalImage,
  
  clientsContainer, clientsItem, clientsImage,
  serviceDropdown, serviceDropdownText,
  techImage
 }