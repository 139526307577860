import { Container, Button, Typography } from "@mui/material";
import validator from 'validator';
import { AnimatePresence, motion } from "framer-motion";
import { useState, useRef } from "react";

import ModuleHeader from "../common/ModuleHeader";
import FormFields from "./FormFields";
import { popUpAnimation } from "../../utils/animations";
import { usePopupVisibility } from "../../contexts/Contexts";

import { headingSWhitec, formBusinessApplicationContainer, 
  formCloseButton, formCloseButtonText } from "../../theme/styles";
  import emailTemplate from './emailTemplate';

export default function FormPopUp({isVisible, data, recaptchaKey }) {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [text, setText] = useState()
  const [company, setCompany] = useState()
  const [companyType, setCompanyType] = useState()
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState('')
  const [errorEmailAlert, setErrorEmailAlert] = useState(null)
  const [errorPhoneAlert, setErrorPhoneAlert] = useState(null)
  const [errorTypeAlert, setErrorTypeAlert] = useState(null)
  const [errorFileAlert, setErrorFileAlert] = useState(null)
  const [errorCaptcha, setErrorCaptcha] = useState(null)
  const [messageSent, setMessageSent] = useState(false)
  
  const recaptchaRef = useRef();

  const context = usePopupVisibility()

  const handleChange = (event) => {
    switch(event.target.id) {
      case 'name':
        setName(event.target.value)
        break
      case 'email':
        setErrorEmailAlert(null)
        setEmail(event.target.value)
        break
      case 'phone':
        setErrorPhoneAlert(null)
        setPhone(event.target.value)
        break
      case 'company':
        setCompany(event.target.value)
        break
      case 'text':
        setText(event.target.value)
        break
      case 'cv':
        if (getFileExtension(event.target.files[0].name).toLowerCase() === 'pdf') {
          setErrorFileAlert(null)
          setFile(event.target.files[0])
          setFileName(event.target.files[0].name)
        } else {
          const fileEntry = data.form_option_entries?.data?.find(item => item.attributes?.identifier === "cv")
          setFile()
          event.target.value=null
          setErrorFileAlert(fileEntry?.attributes?.errorMessage)
        }
        break
      default: 
        setErrorTypeAlert(null)
        setCompanyType(event.target.value)
        break
    }
  }
  const getFileExtension = (f) => {
    return f.split('.').pop()
  }

  const handleContact = async () => {
    var flag = false
    setErrorEmailAlert(null)
    setErrorPhoneAlert(null)
    setErrorTypeAlert(null)
    setErrorCaptcha(null)
    const recaptchaToken = await recaptchaRef.current.execute()
    const emailEntry = data.form_option_entries?.data?.find(item => item.attributes?.identifier === "email")
    if (emailEntry && (!email || !validator.isEmail(email))){
      flag = true
      setErrorEmailAlert(emailEntry.attributes?.errorMessage)
    }
    const phoneEntry = data.form_option_entries?.data?.find(item => item.attributes?.identifier === "phone")
    if ( phoneEntry && (!phone || !validator.isMobilePhone(phone))){
      flag = true
      setErrorPhoneAlert(phoneEntry.attributes?.errorMessage)
    }
    const companyTypeEntry = data.form_option_entries?.data?.find(item => item.attributes?.identifier === "companyType")
    if (companyTypeEntry && (!companyType)){
      flag = true
      setErrorTypeAlert(companyTypeEntry.attributes?.errorMessage)
    }
    const fileEntry = data.form_option_entries?.data?.find(item => item.attributes?.identifier === "cv")
    if (fileEntry && (!file)){
      flag = true
      setErrorFileAlert(fileEntry.attributes?.errorMessage)
    }
    if (!recaptchaToken) {
      flag = true
      setErrorCaptcha('Verify the captcha')
    }
    if (flag) {
      return false
    }
    if (data.form_option_entries?.data?.some(item => item.attributes?.identifier === "cv")) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        const formData = {
          subject: data.subject,
          email,
          name,
          company,
          companyType,
          phone,
          text,
          recaptchaToken,
        }
        const htmlContent = emailTemplate(formData);
        const requestOptions = {
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            subject: data.subject,
            text: `email: ${email}
              name: ${name}
              phone: ${phone}
              message: ${text}
              company: ${company}
              type: ${companyType}
            `,
            attachments: [
              { 
                content: reader?.result?.toString()?.split(',')[1],
                filename: file.name,
                type: "application/pdf",
                disposition: "attachment"
              }
            ],
            html: htmlContent,
            recaptchaToken: recaptchaToken,
          })
        }
        const response = await fetch(`${process.env.REACT_APP_STRAPI_BASE_URL}contact/submit`, requestOptions);
        const jsonData = await response.json()
        if (jsonData.success) {
          setMessageSent(true);
        } else {
          console.log('Error: ' + jsonData.message);
        }
      }
    } else {
      const sender = async () => {
        const formData = {
          subject: data.subject,
          email,
          name,
          company,
          companyType,
          phone,
          text,
          recaptchaToken,
        }
        const htmlContent = emailTemplate(formData);
        const requestOptions = {
          method: 'POST', 
          headers: { 'Content-Type': 'application/json' }, 
          body: JSON.stringify({
            subject: data.subject,
            text: `email: ${email}
              name: ${name}
              phone: ${phone}
              message: ${text}
              company: ${company}
              type: ${companyType}
            `,
            attachments: [],
            html: htmlContent,
            recaptchaToken: recaptchaToken,
          })
        }
        const response = await fetch(`${process.env.REACT_APP_STRAPI_BASE_URL}contact/submit`, requestOptions);
        const jsonData = await response.json()
        if (jsonData.success) {
          setMessageSent(true);
        } else {
          console.log('Error: ' + jsonData.message);
        }
      }
      sender()
    }
    
  }

  const closeModal = () => {
    setName()
    setEmail()
    setPhone()
    setText()
    setFile()
    setCompanyType()
    setCompany()
    setFileName('')
    setErrorEmailAlert(null)
    setErrorPhoneAlert(null)
    setErrorTypeAlert(null)
    setErrorFileAlert(null)
    setErrorCaptcha(null)
    setMessageSent(false)
    context.toggleAppPopup()
  }

  return (
    <AnimatePresence>
      {isVisible && data &&
      <Container
        disableGutters 
        component={motion.div}
        initial={popUpAnimation.hidden}
        animate={popUpAnimation.visible}
        exit={popUpAnimation.hidden}
        transition={popUpAnimation.transition}
        sx={formBusinessApplicationContainer}>
        <Container disableGutters >
          <Button 
            onClick={() => {closeModal()}} 
            sx={formCloseButton}>
            <Typography sx={formCloseButtonText}>{'X'}</Typography>
          </Button>
          <ModuleHeader
            title={data.name}
            titleClass={headingSWhitec}
            />
        </Container>
        {
        messageSent === true ? 
        <Typography style={{margin: 'auto'}} sx={headingSWhitec}>{data.successMessage}</Typography>
        :
        <FormFields 
          array={data.form_option_entries?.data} 
          handleChange={handleChange}
          handleContact={handleContact}
          buttonText={data.buttonText}
          fileName={fileName}
          recaptchaRef={recaptchaRef}
          recaptchaKey={recaptchaKey}
          errorEmailAlert={errorEmailAlert}
          errorPhoneAlert={errorPhoneAlert}
          errorTypeAlert={errorTypeAlert}
          errorFileAlert={errorFileAlert}
          errorCaptcha={errorCaptcha}
          checkBoxText={data.checkBoxText}
          typeValue={companyType}
          />
        }
      </Container>}
    </AnimatePresence>
  )
}