import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { Container, Grid } from "@mui/material";
import ReactMarkdown from 'react-markdown';
import Menu from "../menu/Menu";

import { caseContainer, containerFitWhitec, containerMenuWhitec, flexColumn } from "../../theme/styles";

export default function AuxView({data}) {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const elementRef = useRef(null);

  const useWindowSize  = () => {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([elementRef.current.offsetWidth, elementRef.current.offsetHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize(elementRef)

  useEffect(() => {
    setSize([elementRef.current.offsetWidth, elementRef.current.offsetHeight]);
  }, [data])

  return (
    data &&
    <Container ref={elementRef} disableGutters maxWidth={false} sx={[containerFitWhitec, flexColumn, {overflow: 'visible'}]} >
      <Container maxWidth={false} sx={containerMenuWhitec}>
        <Menu 
          width={width} 
          height={height}
          data={data.menu} 
          option={'auxView'} 
          />
      </Container>
      <Grid container sx={caseContainer}>
        <div className="markdownWrapper">
        <ReactMarkdown>{data.content}</ReactMarkdown>
        </div>
      </Grid>
    </Container>
  )
}